<template>
  <p :class="['paragraph',{'paragraph--bold' : bold},
													{'paragraph--micro' : micro},
													{'paragraph--feature' : feature},
													{'paragraph--align-center' : align === 'center'},
													{'paragraph--align-right' : align === 'right'},
													{'paragraph--margin-none' : margin === 'none'},
													{'paragraph--whiteout' : whiteout}]">
		<slot></slot>
	</p>
</template>

<script>
export default {
	props: {
		bold: Boolean,
		micro: Boolean,
		feature: Boolean,
		align: String,
		margin: String,
		whiteout: Boolean,
	},
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.paragraph{
	font-family:var(--paragraph-font-family);
	color:var(--paragraph-color);
	font-size: var(--paragraph-font-size);
	line-height: var(--paragraph-line-height);
	margin: var(--paragraph-margin);
	transition: var(--paragraph-transition)
}
.paragraph--bold{
	font-weight: bold;
}
.paragraph--micro{
	font-size: var(--paragraph-micro-font-size);
	color: var(--paragraph-micro-color);
	line-height: var(--paragraph-micro-line-height);
	margin: var(--paragraph-micro-margin);
}
.paragraph--feature{
	font-size: var(--paragraph-feature-font-size);
	color: var(--paragraph-feature-color);
	line-height: var(--paragraph-feature-line-height);
	margin: var(--paragraph-feature-margin);
}
.paragraph--align-center{
	text-align: center;
}
.paragraph--align-right{
	text-align: right;
}
.paragraph--margin-none{
	margin: 0;
}
.paragraph--whiteout{
	color: #fff;
}

.navigation .paragraph{
	padding: var(--paragraph-navigation-padding);
}
</style>

<!-- Used in : AR, DS, DS -->